import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserInfo } from 'core-models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject('config') private config,
              private httpClient: HttpClient) { }

  signin(username: string, password: string) {
    const serverUrl: string = `${this.config.serverUrl}/api/auth/`;
    return this.httpClient
              .post(serverUrl, {username, password})
              .pipe(
                tap((responseJson) => {
                  this.storeInLocalStorage(this.config.accessTokenParamName, responseJson['access']);
                  this.storeInLocalStorage(this.config.refreshTokenParamName, responseJson['refresh']);
                })
              );
  }

  createPasswordRecoveryRequest(email: string): Observable<any> {
    const serverUrl = `${this.config.serverUrl}/security/password_recovery_request?email=${email}`;
    return this.httpClient.get(serverUrl);
  }

  validatePasswordRecoveryToken(token: string): Observable<any> {
      const serverUrl = `${this.config.serverUrl}/security/validate_password_recovery_token?token=${token}`;
      return this.httpClient.get(serverUrl);
  }

  getLoggedUserInfo(): Observable<UserInfo> {
    const serverUrl = `${this.config.serverUrl}/api/session/user_info/`;
    return this.httpClient.get<UserInfo>(serverUrl);
  }

  getAccessToken() {
    return localStorage.getItem(this.config.accessTokenParamName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.config.refreshTokenParamName);
  }

  storeUserInfoInLocalStorage(userInfo: UserInfo) {
      const userInfoStr: string = JSON.stringify(userInfo);
      this.storeInLocalStorage(this.config.userInfoParamName, userInfoStr);
  }

  getUserInfoFromLocalStorage(): UserInfo {
      const userInfo = localStorage.getItem(this.config.userInfoParamName);
      return JSON.parse(userInfo) as UserInfo;
  }

  storeInLocalStorage(key: string, value: string) {
      localStorage.setItem(key, value);
  }

  logout() {
    localStorage.clear();
  }
}
