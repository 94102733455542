import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Address, City, Customer, DeliveryZone, Store } from 'core-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(@Inject('config') private config,
              private httpClient: HttpClient) { }

  getCities(): Observable<City[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/catalog/cities/`;
      return this.httpClient.get<City[]>(serverUrl);
  }

  getStores(): Observable<Store[]> {
    const serverUrl: string = `${this.config.serverUrl}/api/catalog/stores/`;
    return this.httpClient.get<Store[]>(serverUrl);
  }

  getDeliveriesZones(): Observable<DeliveryZone[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/catalog/delivery_zones/`;
      return this.httpClient.get<DeliveryZone[]>(serverUrl);
  }

  getDeliveriesZonesByCity(city: string): Observable<DeliveryZone[]> {
    const serverUrl: string = `${this.config.serverUrl}/api/catalog/delivery_zones/?city=${city}`;
    return this.httpClient.get<DeliveryZone[]>(serverUrl);
  }

  getCustomerByPhoneNumber(phoneNumber: string): Observable<Customer> {
      const serverUrl: string = `${this.config.serverUrl}/api/catalog/customers/search_by_phone_number/?phone_number=${phoneNumber}`;
      return this.httpClient.get<Customer>(serverUrl);
  }

  saveCustomer(customer: Customer): Observable<Customer> {
      const serverUrl: string = `${this.config.serverUrl}/api/catalog/customers/`;
      return this.httpClient.post<Customer>(serverUrl, customer);
  }

  saveAddress(address: Address): Observable<Address> {
    const serverUrl: string = `${this.config.serverUrl}/api/catalog/addresses/`;
    return this.httpClient.post<Address>(serverUrl, address);
  }

  getCustomers(): Observable<Customer[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/catalog/customers/`;
      return this.httpClient.get<Customer[]>(serverUrl);
  }

  getAddressesByPhoneNumber(phoneNumber: string): Observable<Address[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/catalog/addresses/?phone_number=${phoneNumber}`;
      return this.httpClient.get<Address[]>(serverUrl);
  }

}
