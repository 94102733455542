import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { Category, Item, ItemPayload } from 'core-models';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(@Inject('config') private config,
              private httpClient: HttpClient) { }


  saveItem(itemPayload: ItemPayload): Observable<Item> {
      let saveOperation$: Observable<Item> = null;
      let serverUrl: string = null;
      let basePath = `${this.config.serverUrl}/api/inventory/items/`;
      const payload: Item = {... itemPayload};
      if (itemPayload.id != null) {
          serverUrl = `${basePath}${itemPayload.id}/`;
          saveOperation$ = this.httpClient.put<Item>(serverUrl, payload);
      } else {
          serverUrl = basePath;
          saveOperation$ = this.httpClient.post<Item>(serverUrl, payload);
      }

      return saveOperation$.pipe(switchMap((item: Item) => {
                    if (itemPayload.imageFile) {
                        return this.uploadItemImage(item, itemPayload.imageFile);
                    }
                    return of(item);
              }));
  }

  private uploadItemImage(item: Item, image: any): Observable<Item> {
      const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/${item.id}/upload_image/`;
      const formData = new FormData();
      formData.append('file', image);
      return this.httpClient
                .put<Item>(serverUrl, formData);
  }

  getCategories(): Observable<Category[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/`;
      return this.httpClient.get<Category[]>(serverUrl);
  }

  getItemBySlug(slug: string): Observable<Item> {
      const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/${slug}/search_by_slug/`;
      return this.httpClient.get<Item>(serverUrl);
  }

  changeItemStatus(item: Item, status: string): Observable<Item> {
      const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/${item.id}/change_status/?status=${status}`;
      return this.httpClient.get<Item>(serverUrl);
  }

  getItems(): Observable<Item[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/`;
      return this.httpClient.get<Item[]>(serverUrl);
  }

  getItemsAvailableToOrder(): Observable<Item[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/availables_to_order/`;
      return this.httpClient.get<Item[]>(serverUrl);
  }

  getItemsAvailableSuggestions(itemId: number): Observable<Item[]> {
    const serverUrl: string = `${this.config.serverUrl}/api/inventory/items/${itemId}/available_items_suggestions/`;
    return this.httpClient.get<Item[]>(serverUrl);
  }

}
