import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    data: { authPermission: 'noauth' },
    loadChildren: () =>
      import('./pages/home/home.module').then((mod) => mod.HomeModule),
  },
  {
    path: 'cart',
    data: { authPermission: 'noauth' },
    loadChildren: () =>
      import('./pages/cart/cart.module').then((mod) => mod.CartModule),
  },
  {
    path: 'item-detail',
    data: { authPermission: 'noauth' },
    loadChildren: () =>
      import('./pages/item-detail/item-detail.module').then(
        (mod) => mod.ItemDetailModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
