import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService, AuthGuard, AuthInterceptor, InventoryService, CatalogService} from './services';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class CoreServicesModule {

    public static forRoot(config): ModuleWithProviders<CoreServicesModule> {
          return {
              ngModule: CoreServicesModule,
              providers: [
                {provide: 'config', useValue: config},
                {
                  provide: HTTP_INTERCEPTORS,
                  useClass: AuthInterceptor,
                  multi: true
                },
                AuthService,
                AuthGuard,
                InventoryService,
                CatalogService
              ]
          };
    }

}

