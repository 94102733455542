import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaymentType, UrgencyIndicator } from 'core-models/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(@Inject('config') private config,
              private httpClient: HttpClient) { }


  getPaymentTypes(): Observable<PaymentType[]> {
      const serverUrl: string = `${this.config.serverUrl}/api/common/payment_types/`;
      return this.httpClient.get<PaymentType[]>(serverUrl);
  }

  getUrgencyIndicators(): Observable<UrgencyIndicator[]> {
    const serverUrl: string = `${this.config.serverUrl}/api/common/urgency_indicators/`;
    return this.httpClient.get<UrgencyIndicator[]>(serverUrl);
  }
}
